<template>
  <div class="main">
    <TableMain
      tableTitle="Plan de Trabajo y Mejoramiento"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Crear Actividad"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="[
        '#',
        'Fecha Límite',
        'Responsable',
        'Tipo',
        'Origen',
        'Estado'
      ]"
      :data="summarys"
      :editOption="true"
      :deleteOption="true"
      :minutesOption="true"
      :executeOption="true"
      @editAction="editEvent($event)"
      @executeAction="executeEvent($event)"
      @minutesAction="minutesEvent($event)"
      @deleteAction="deleteEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
    <Report :runNow="runReport" style="display: none"></Report>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";
import { prettyDate, titleCase } from "@/modules/tools";

import Report from "./SummaryReportv3";

export default {
  components: { Legal, TableMain, Report },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Plan de Trabajo / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    minutesEvent(_id) {
      const store = new Storage();
      store.getData("summary", _id).then(summary => {
        if (summary.status !== "EJECUTADO") {
          Swal.fire({
            icon: "error",
            text:
              "Para generar el Reporte de Ejecución es necesario Ejecutar la Actividad"
          });
        } else {
          this.runReport = _id;
        }
      });
    },
    getEmployee(employeeID, position) {
      this.employees.forEach(employee => {
        if (employee._id === employeeID) {
          this.summarys[position][2] = employee.name;
        }
      });
    },
    editEvent(_id) {
      this.$router.push({ name: "Plain Summary Edit", params: { _id: _id } });
    },
    executeEvent(_id) {
      const store = new Storage();
      store.getData("summary", _id).then(summary => {
        if (summary.status === "EJECUTADO") {
          Swal.fire({
            icon: "error",
            title: "Esta actividad ya se encuentra ejecutada"
          });
        } else {
          this.$router.push({
            name: "Plain Summary Execute",
            params: { _id: _id, branchId: this.$route.params._id }
          });
        }
      });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("summary", _id).then(summary => {
        Swal.fire({
          title: "¿Eliminar Actividad del Plan de Trabajo?",
          text: summary.description,
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando actividad",
              html:
                "Por favor espere mientras se elimina la actividad</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            if (summary.support) {
              store.deleteData("optima", "file", summary.support).then(() => {
                store.deleteData("optima", "summary", _id).then(() => {
                  Swal.fire({
                    icon: "success",
                    title: "Actividad eliminada",
                    text: "Todos los datos han sido sincronizados"
                  }).then(() => {
                    this.$router.go();
                  });
                });
              });
            } else {
              store.deleteData("optima", "summary", _id).then(() => {
                Swal.fire({
                  icon: "success",
                  title: "Actividad eliminada",
                  text: "Todos los datos han sido sincronizados"
                }).then(() => {
                  this.$router.go();
                });
              });
            }
          }
        });
      });
    }
  },
  created() {
    const store = new Storage();
    let aux = 0;
    store.getData("employee").then(employees => {
      this.employees = employees;
      store.getData("summary").then(summary => {
        /*
        let VENCIDO = [];
        const POR_VENCER = [];
        const PROGRAMADO = [];
        const EJECUTADO = [];
        */

        summary.sort((a, b) => {
          return a.limitDate > b.limitDate ? -1 : 1;
        });
        /*
        summary.forEach(area => {
          if (area.status === "VENCIDO") {
            VENCIDO.push(area);
          } else if (area.status === "POR_VENCER") {
            POR_VENCER.push(area);
          } else if (area.status === "PROGRAMADO") {
            PROGRAMADO.push(area);
          } else if (area.status === "EJECUTADO") {
            EJECUTADO.push(area);
          }
        });
        VENCIDO = VENCIDO.concat(POR_VENCER);
        VENCIDO = VENCIDO.concat(PROGRAMADO);
        VENCIDO = VENCIDO.concat(EJECUTADO);
        summary = VENCIDO;
        */

        summary.forEach(area => {
          if (area.branch === this.$route.params._id) {
            prettyDate(area.limitDate).then(limitDate => {
              this.summarys.push([
                area._id,
                limitDate,
                null,
                titleCase(area.suggested.split("_").join(" ")),
                titleCase(area.origin.split("_").join(" ")),
                titleCase(area.status.split("_").join(" "))
              ]);
              this.getEmployee(area.responsable, aux);
              aux++;
            });
          }
        });
      });
    });
  },
  data() {
    return {
      summarys: [],
      employees: [],
      runReport: false
    };
  },
  name: "Summary Branch"
};
</script>
